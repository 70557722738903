import firebase from "firebase/app"
import "firebase/auth" 
import "firebase/firestore"
import "firebase/analytics"
import "firebase/storage"
// Firebase web config
const config = {
  apiKey: "AIzaSyACXnhbYWWkn-6cqQmwCqlbS-U1GcLOGGQ",
  authDomain: "commissions-c943e.firebaseapp.com",
  databaseURL: "https://commissions-c943e.firebaseio.com",
  projectId: "commissions-c943e",
  storageBucket: "commissions-c943e.appspot.com",
  messagingSenderId: "763176419456",
  appId: "1:763176419456:web:091bf42a790600d2aae665",
  measurementId: "G-840ELJ7SXV",
}

let instance = null

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }

  return null
}
